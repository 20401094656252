const CONFIG = {
  chainId: 1,
  chainName: "homestead",
  chainIdHexa: "0x1",
  contractAddress: "0x6BD6b6Ecb9Ba5bD98D592a1365D3aB242fc0ebD9", // staking contract that we deploy on remix on 22Dec 2024
  tokenAddress: "0x10008002e4465835278bc0f85afba12aca2b3997", // VERSX token .
  tokenDecimals: "10",
  tokenSymbol: "VERSX",
  tokenName: "VersX",
};

export default CONFIG;
