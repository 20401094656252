import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "./styles/sidebar.css";

import App from "./App.js";
import { GlobalProvider } from "./context/GlobalContext";
import { http, createConfig, WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { getDefaultConfig, ConnectKitProvider } from "connectkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const projectId = process.env.REACT_APP_WCPROJECTID;

export const configChain = createConfig({
  chains: [mainnet],
  transports: {
    [mainnet.id]: http("https://damp-compatible-feather.quiknode.pro/"),
  },
});

const queryClient = new QueryClient();

const config = createConfig(
  getDefaultConfig({
    // Your dApps chains
    chains: [mainnet],
    transports: {
      // RPC URL for each chain
      [mainnet.id]: http("https://damp-compatible-feather.quiknode.pro/"),
    },

    // Required API Keys
    walletConnectProjectId: projectId,

    appName: "ETH Staking", // Your app name
    // Optional
    appDescription: "ETH Staking | Versx",
    appUrl: "https://ethstaking.versx.io/", // your app's url
  })
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider theme='auto' mode='dark'>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
