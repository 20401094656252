import { useContext, useEffect, useState } from "react";
import AnimatedNumber from "animated-number-react";
import CONFIG from "../abi/config.js";
import Moralis from "moralis";
import { useAccount } from "wagmi";
import { GlobalContext } from "../context/GlobalContext.js";
import StakeDetails from "./elements/StakeDetails.js";
import StakeForm from "./elements/StakeForm.js";
import {
  formattedNumber,
  formattedNumberNine,
  getFullDisplayBalance,
} from "../utils/formatNumber.js";
import Banner from "./Banner.js";

const Main = ({ setError, setErrMsg }) => {
  const { address } = useAccount();
  const { blockChainData } = useContext(GlobalContext);

  const [tokenPrice, setTokenPrice] = useState(0);
  const plans = [
    {
      plan: 0,
      duration: "30 days",
      apy: blockChainData.apy.one_month_apy,
    },
    {
      plan: 1,
      duration: "60 days",
      apy: blockChainData.apy.two_month_apy,
    },
    {
      plan: 2,
      duration: "90 days",
      apy: blockChainData.apy.three_month_apy,
    },
    {
      plan: 3,
      duration: "180 days",
      apy: blockChainData.apy.six_month_apy,
    },
    {
      plan: 4,
      duration: "365 days",
      apy: blockChainData.apy.one_year_apy,
    },
  ];
  const [plan, setPlan] = useState(plans[0]);
  // You can adjust the token and blockchain. More details here: https://docs.moralis.io/web3-data-api/evm/reference/get-token-price
  async function getTokenPrice() {
    let myTokenAddress = CONFIG.tokenAddress;
    console.log("tokenAddress: " + myTokenAddress);

    try {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS_API_KEY,
      });

      const response = await Moralis.EvmApi.token.getTokenPrice({
        chain: "0x1",
        include: "percent_change",
        address: myTokenAddress,
      });

      console.log(response.raw);
      console.log("usdprice", response.raw.usdPrice);
      setTokenPrice(response.raw.usdPrice);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getTokenPrice();
  }, []);

  return (
    <div className='  px-2 md:px-0 mx-auto  md:my-8 my-5 '>
      <div className='grid w-full grid-cols-1 gap-8  items-stretch	 md:grid-cols-2'>
        <div className='w-full p-8 backdrop-blur-lg flex flex-grow  justify-around flex-col gap-3 border-opacity-30 border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  '>
          <div className='flex mt-5 w-full md:flex-row flex-col justify-between'>
            <div className='w-full'>
              <div className='uppercase  flex gap-2  mb-2 font-semibold text-md    text-left'>
                TOTAL{" "}
                <span className='text-cdark	font-bold'>{CONFIG.tokenName}</span>{" "}
              </div>{" "}
              <div className='uppercase w-full mb-2 font-semibold text-md    text-left'>
                {/*{CONFIG.tokenSymbol} */}
                Tokens Staked
              </div>{" "}
            </div>
            <div className='bg-vgray rounded-xl border-[1px] border-opacity-20 border-vyellow  w-full flex items-center md:py-0 py-4 px-3 justify-between'>
              {" "}
              <div className='font-extrabold text-lg w-full   text-left'>
                {Object.values(blockChainData.StakeBalance).some(
                  (balance) =>
                    balance !== undefined && balance !== null && balance !== ""
                )
                  ? formattedNumberNine(
                      Object.values(blockChainData.StakeBalance).reduce(
                        (sum, balance) => sum + parseInt(balance),
                        0
                      )
                    )
                  : "0.00"}{" "}
              </div>
              <img
                src='/favicon.ico'
                className='w-10 md:w-10 ml-4 mx-auto    md:h-10 h-10 '
                alt='StakingPlatform'
              />
            </div>
          </div>
          <div className=' mt-4 px-3 border-b-[1px] border-b-vyellow'></div>
          <StakeDetails plan={plan} plans={plans} setPlan={setPlan} />
          <StakeForm
            setError={setError}
            setErrMsg={setErrMsg}
            plan={plan}
          />{" "}
          <Banner />
        </div>
        <div className=' w-full flex flex-col flex-grow gap-12 z-10'>
          <div className='flex items-stretch flex-col md:flex-row gap-12 md:gap-4 '>
            <div className='w-full  flex-col md:flex-row flex justify-between gap-8  '>
              <div className='flex md:flex-row flex-col md:gap-0 gap-4 items-center py-4 border-opacity-30  justify-between px-5 backdrop-blur-lg  border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  justify-between w-full'>
                <div>
                  {" "}
                  <svg
                    id='chart'
                    width='100%'
                    height='50'
                    viewBox='0 0 80 30'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <defs>
                      <linearGradient
                        id='linear-gradient'
                        y1='2'
                        x1='1'
                        x2='1'
                        gradientUnits='objectBoundingBox'
                      >
                        {" "}
                        <stop offset='.241' stopColor='#000000' />
                        <stop offset='.71' stopColor='#DEDF1B' />
                        <stop offset='1' stopColor='#DEDF1B' />
                      </linearGradient>
                    </defs>
                    <g fill='url(#linear-gradient)'>
                      <path d='M0,30  h8  v-0.7098142461564727  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M10,30  h8  v-3.8886121736656065  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M20,30  h8  v-7.535516905963142  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M30,30  h8  v-9.867032085607057  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M40,30  h8  v-14.308029708931354  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M50,30  h8  v-13.650666700270385  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M60,30  h8  v-17.03132200231409  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                      <path d='M70,30  h8  v-19.35071883329862  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z' />
                    </g>
                  </svg>
                </div>{" "}
                <div className='flex gap-4 flex-col'>
                  <p className='text-xs text-center md:text-right   '>
                    Token Price
                  </p>
                  <div className='font-extrabold flex flex-col gap-2 '>
                    <div className='font-extrabold    '>
                      {" "}
                      $
                      <AnimatedNumber
                        value={tokenPrice}
                        duration='2000'
                        formatValue={(value) =>
                          parseFloat(value).toLocaleString("en-US", {
                            maximumFractionDigits: 10,
                          })
                        }
                      ></AnimatedNumber>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex md:flex-row flex-col md:gap-0 gap-4 items-center py-4  border-opacity-30  justify-between px-5 backdrop-blur-lg  border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  justify-between w-full'>
                <div>
                  {" "}
                  <div className='uppercase text-center md:text-left   '>
                    {" "}
                    <div className='text-xs '>
                      <span className=' '> TVL:&nbsp;$ </span>
                      <AnimatedNumber
                        value={
                          blockChainData.TotalStaked && tokenPrice
                            ? blockChainData.TotalStaked * tokenPrice
                            : "0.00"
                        }
                        duration='2000'
                        formatValue={(value) =>
                          parseFloat(value).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })
                        }
                      ></AnimatedNumber>
                    </div>{" "}
                  </div>
                  <svg
                    id='chart'
                    width='100%'
                    height='58'
                    viewBox='0 0 120 58'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <defs>
                      <linearGradient
                        id='linear-gradient'
                        x1='0.5'
                        x2='0.5'
                        y2='1'
                        gradientUnits='objectBoundingBox'
                      >
                        <stop offset='.241' stopColor='#000000' />
                        <stop offset='.71' stopColor='#DEDF1B' />
                        <stop offset='1' stopColor='#DEDF1B' />
                      </linearGradient>
                    </defs>
                    <path
                      d='  M0,50  h12  v-0.2691140789513824  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M15,50  h12  v-6.1035534408604875  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M30,50  h12  v-12.85295830169808  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M45,50  h12  v-17.121537297632287  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M60,50  h12  v-27.99841373754431  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M75,50  h12  v-32.02205622140259  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M90,50  h12  v-29.322334941779435  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                    <path
                      d='  M105,50  h12  v-24.598795157806162  q0,-0 -0,-0  h-12  q-0,0 -0,0  Z'
                      fill='url(#linear-gradient)'
                    />
                  </svg>
                </div>
                <div className='flex gap-4 flex-col'>
                  <p className='text-xs text-center md:text-right    '>
                    {" "}
                    Total Staked
                  </p>

                  <div className='font-extrabold text-right flex flex-col gap-2 '>
                    <div className='font-extrabold  text-sm '>
                      <AnimatedNumber
                        value={
                          blockChainData.TotalStaked
                            ? blockChainData.TotalStaked
                            : "0.00"
                        }
                        duration='2000'
                        formatValue={(value) =>
                          parseFloat(value).toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                          })
                        }
                      ></AnimatedNumber>
                      &nbsp;
                      {CONFIG.tokenSymbol}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-stretch flex-col md:flex-row gap-12 md:gap-4 '>
            <div className='w-full p-8 text-left flex md:flex-row flex-col items-center gap-2  border-opacity-30  justify-between backdrop-blur-lg  border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border  bg-vgray_3   bg-opacity-10  '>
              <div className='flex flex-col gap-3'>
                <div className='  text-sm  my-2   '>Total Claimed Rewards</div>
                <div className='font-extrabold flex  md:mx-0 mx-auto  text-vyellow gap-2 '>
                  <AnimatedNumber
                    value={
                      blockChainData.TotalRewards
                        ? blockChainData.TotalRewards
                        : "0.00"
                    }
                    duration='2000'
                    formatValue={(value) =>
                      parseFloat(value).toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                      })
                    }
                  ></AnimatedNumber>
                  &nbsp;
                  {CONFIG.tokenSymbol}
                </div>
              </div>
              <div className='  '>
                <svg
                  id='chart'
                  width='100%'
                  height='80'
                  viewBox='0 0 80 30'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <defs>
                    <linearGradient
                      id='linear-gradient'
                      x1='0.5'
                      x2='0.5'
                      y2='1'
                      gradientUnits='objectBoundingBox'
                    >
                      <stop offset='.241' stopColor='#000000' />
                      <stop offset='.71' stopColor='#DEDF1B' />
                      <stop offset='1' stopColor='#DEDF1B' />
                    </linearGradient>
                  </defs>
                  <path
                    d='  M0,30  h8  v-0.3559955903768852  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M10,30  h8  v-3.2730281691498107  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M20,30  h8  v-4.129306820057437  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M30,30  h8  v-11.36131403794074  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M40,30  h8  v-10.952211091320216  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M50,30  h8  v-14.499437557106283  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M60,30  h8  v-16.20159452944091  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                  <path
                    d='  M70,30  h8  v-19.139616076129972  q0,-0 -0,-0  h-8  q-0,0 -0,0  Z'
                    fill='url(#linear-gradient)'
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className='hidden md:flex flex-row gap-10   justify-end     sm:mt-0   sm:p-0'>
            <h1 className='uppercase text-7xl tracking-widest text-vyellow font-bold'>
              APR
            </h1>
            <h1 className='font-bold text-7xl tracking-widest text-vyellow  '>
              {plan.apy && parseFloat(plan.apy.toString()) / 100}%
            </h1>
          </div>
          {address && (
            <div className='stakePanel  bg-transparent md:py-0 py-2   w-full  '>
              <div className='uppercase font-bold text-lg font-Poppins px-6 md:px-0 text-center md:text-left'>
                CLAIMABLE AFTER TENURE COMPLETION
              </div>
              <div className='flex flex-col text-left  md:flex-row items-center  px-0  justify-around mt-6  gap-y-10 gap-x-2 '>
                <div className='w-full p-8  justify-around backdrop-blur-lg h-full md:h-56 flex flex-col gap-1  border-opacity-30  border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border     '>
                  <div className='text-lg text-vyellow text-left font-bold mb-4 uppercase '>
                    30 Days{" "}
                    <div className='border-b mt-5 border-vyellow border-opacity-20'></div>
                  </div>{" "}
                  <p className='text-xs   '>
                    <span className=' '> Rewards/Day: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.RewardBalance.plan0
                        ? formattedNumber(blockChainData.RewardBalance.plan0)
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs '>
                    <span className=' '> After 30 Days: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.TotalStakedPerAccount.plan0
                        ? getFullDisplayBalance(
                            blockChainData.TotalStakedPerAccount.plan0,
                            CONFIG.tokenDecimals
                          )
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs  '>
                    Staked Amount:
                    <span className='font-extrabold text-vyellow'>
                      {" "}
                      {formattedNumber(blockChainData.StakeBalance.plan0)}{" "}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                </div>
                <div className='w-full  justify-around p-8 backdrop-blur-lg h-full md:h-56 flex flex-col gap-1 border-opacity-30  border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border     '>
                  <div className='text-lg text-vyellow text-left font-bold mb-4 uppercase '>
                    60 Days{" "}
                    <div className='border-b mt-5 border-vyellow border-opacity-20'></div>
                  </div>
                  <p className='text-xs  '>
                    <span className=' '> Rewards/Day: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.RewardBalance.plan1
                        ? formattedNumber(blockChainData.RewardBalance.plan1)
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs '>
                    <span className=' '> After 30 Days: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.TotalStakedPerAccount.plan1
                        ? getFullDisplayBalance(
                            blockChainData.TotalStakedPerAccount.plan1,
                            CONFIG.tokenDecimals
                          )
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs  '>
                    Staked Amount:
                    <span className=' font-extrabold text-vyellow'>
                      {" "}
                      {formattedNumber(blockChainData.StakeBalance.plan1)}{" "}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                </div>
                <div className='w-full p-8 justify-around backdrop-blur-lg  h-full md:h-56 flex flex-col gap-1 border-opacity-30  border-solid border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border     '>
                  <div className='text-lg text-vyellow text-left font-bold mb-4 uppercase '>
                    {" "}
                    90 Days{" "}
                    <div className='border-b mt-5 border-vyellow border-opacity-20'></div>
                  </div>
                  <p className='text-xs  '>
                    <span className=' '> Rewards/Day: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.RewardBalance.plan2
                        ? formattedNumber(blockChainData.RewardBalance.plan2)
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs '>
                    <span className=' '> After 30 Days:</span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.TotalStakedPerAccount.plan2
                        ? getFullDisplayBalance(
                            blockChainData.TotalStakedPerAccount.plan2,
                            CONFIG.tokenDecimals
                          )
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>{" "}
                  <p className='text-xs  '>
                    Staked Amount:
                    <span className=' text-vyellow'>
                      {" "}
                      {formattedNumber(blockChainData.StakeBalance.plan2)}{" "}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                </div>
              </div>

              <div className='flex flex-col md:flex-row items-center  px-0  justify-around mt-6  gap-10  '>
                <div className='w-full p-8 text-left backdrop-blur-lg justify-around flex flex-col gap-1 border-solid border-opacity-30  border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border     '>
                  <div className='text-lg text-vyellow text-left font-bold mb-4 uppercase '>
                    {" "}
                    180 Days{" "}
                    <div className='border-b mt-5 border-vyellow border-opacity-20'></div>
                  </div>
                  <p className='text-xs  '>
                    <span className='t '> Rewards/Day: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.RewardBalance.plan3
                        ? formattedNumber(blockChainData.RewardBalance.plan3)
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs '>
                    <span className=' '> After 30 Days: </span>
                    <span className='font-extrabold  text-vyellow'>
                      {blockChainData.TotalStakedPerAccount.plan3
                        ? getFullDisplayBalance(
                            blockChainData.TotalStakedPerAccount.plan3,
                            CONFIG.tokenDecimals
                          )
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>{" "}
                  <p className='text-xs  '>
                    Staked Amount:
                    <span className=' font-extrabold  text-vyellow'>
                      {" "}
                      {formattedNumber(blockChainData.StakeBalance.plan3)}{" "}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                </div>
                <div className='w-full p-8 justify-around text-left backdrop-blur-lg  flex flex-col gap-1 border-solid border-opacity-30  border-vyellow rounded-2xl shadow-lg shadow-vyellow_1 border-1 border     '>
                  <div className='text-lg text-vyellow text-left font-bold mb-4 uppercase '>
                    {" "}
                    365 Days{" "}
                    <div className='border-b mt-5 border-vyellow border-opacity-20'></div>
                  </div>
                  <p className='text-xs '>
                    <span className=' '> Rewards/Day: </span>
                    <span className='font-extrabold  text-vyellow'>
                      {blockChainData.RewardBalance.plan4
                        ? formattedNumber(blockChainData.RewardBalance.plan4)
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                  <p className='text-xs  '>
                    <span className=' '> After 30 Days: </span>
                    <span className='font-extrabold text-vyellow'>
                      {blockChainData.TotalStakedPerAccount.plan4
                        ? getFullDisplayBalance(
                            blockChainData.TotalStakedPerAccount.plan4,
                            CONFIG.tokenDecimals
                          )
                        : "0.00"}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>{" "}
                  <p className='text-xs  '>
                    Staked Amount:
                    <span className=' font-extrabold text-vyellow'>
                      {" "}
                      {formattedNumber(blockChainData.StakeBalance.plan4)}{" "}
                    </span>{" "}
                    {CONFIG.tokenSymbol}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Main;
